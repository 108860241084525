import React from "react"
import { graphql } from "gatsby"
import SiteLayout from "c32-gatsby-theme-core/src/components/layout"
import { SEO, Layout } from "c32-gatsby-theme-core"
import GraphQLErrorList from "c32-gatsby-theme-core/src/components/graphql-error-lists"
import Container from "c32-gatsby-theme-core/src/components/container"
import BlockContent from "../../src/components/block-content"
export const query = graphql`
  query ContactPageQuery {
    page: sanityContactPage(header: { eq: "Contact Dagaz Therapy" }) {
      header
      _rawBlockContent
      seo {
        metaDesc
        metaTitle
      }
    }
  }
`

const ContactPage = (props) => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data && data.page

  if (!page) {
    throw new Error(
      'Missing "Contact" page data. Open the studio at http://localhost:3333 and add "About" page data and restart the development server.'
    )
  }

  return (
    <SiteLayout>
      <SEO title={page.seo.metaTitle} description={page.seo.metaDesc} />
      <Container>
        <h1>{page.header}</h1>
        <BlockContent blocks={page._rawBlockContent || []} />

        <iframe
          id="JotFormIFrame-231896223612052"
          title="Form"
          onLoad={() => window.parent.scrollTo(0, 0)}
          allowTransparency="true"
          allowFullScreen="true"
          allow="geolocation; microphone; camera"
          src="https://form.jotform.com/231896223612052"
          frameBorder="0"
          scrolling="no"
          style={{
            minWidth: "100%",
            maxWidth: "100%",
            height: "4000px",
            border: "none",
          }}
        ></iframe>
      </Container>
    </SiteLayout>
  )
}

export default ContactPage
